<template>
  <div class="login-page">
    <div class="blank-node"></div>
    <div class="l-box" v-if="isShow">
      <div class="logo">
        <img src="imgs/logo.png" alt="" />
      </div>
      <p class="plarfrom-name">畜禽粪污资源利用化平台</p>
      <Form ref="form" :model="form" :rules="rules" :label-width="0">
        <FormItem prop="userName">
          <Input
            v-model="form.userName"
            prefix="md-person"
            placeholder="请输入用户名"
          ></Input>
        </FormItem>
        <FormItem prop="passWord">
          <Input
            v-model="form.passWord"
            prefix="md-lock"
            password
            type="password"
            placeholder="请输入密码"
          ></Input>
        </FormItem>
        <FormItem prop="code">
          <div class="code-box">
            <Input
              v-model="form.code"
              placeholder="请输入验证码"
              style="width: 150px"
            ></Input>
            <img :src="codeSrc" @click="changeCode" alt="" />
          </div>
        </FormItem>
      </Form>
      <p class="btn">
        <Button type="primary" :loading="loading" @click="submit">登录</Button>
      </p>
      <p class="register">
        <span @click="register">注册</span>
        <span @click="getBackPwd">找回密码</span>
      </p>
    </div>
    <register v-if="registerShow" @returnLogin="returnLogin"></register>
    <get-back-password v-if='modelShow' @returnLogin='returnLogin'></get-back-password>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import { V2 } from "@/common/http/api";
import register from "./registers";
import getBackPassword from './getBackPassword'
export default {
  name: "",
  components: {
    register,
    getBackPassword,
  },
  data() {
    return {
      form: {
        userName: "",
        passWord: "",
        accountType: "1",
        code: "",
        qdamnitjm: '1'
      },
      rules: {
        userName: [{ required: true, message: " " }],
        passWord: [{ required: true, message: " " }],
        code: [{ required: true, message: " " }],
      },
      loading: false,
      codeSrc: "",
      //登录显示
      isShow: true,
      modelShow:false,
      registerShow:false,
    };
  },
  computed: {
    ...mapState({
      menuList: (state) => state.permission.menuList,
    }),
  },
  methods: {
    getCode() {
      axios({
        methods: "post",
        headers: {
          withCredentials: true,
        },
        url: V2 + this.$api.USER.GET_CODE,
        responseType: "blob",
      }).then((res) => {
        let blob = new Blob([res.data], { type: "image/jpg" });
        let url = URL.createObjectURL(blob);
        this.codeSrc = url;
      });
    },
    changeCode() {
      this.getCode();
    },
    checkCode() {
      return new Promise((resolve, reject) => {
        axios
          .post(V2 + this.$api.USER.CHECK_CODE + `/${this.form.code}`, {
            Headers: {
              withCredentials: true,
            },
          })
          .then((res) => {
            if (res.data.code == 200) {
              resolve();
            } else {
              reject();
            }
          });
      });
    },
    submit() {
      const chars = 'abacdefghjklmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789';
      let len = 6;
  　　let maxPos = chars.length;
  　　let start = '';
  　　for (let i = 0; i < len; i++) {
  　　　　start += chars.charAt(Math.floor(Math.random() * maxPos));
  　　}
  
      this.$refs.form.validate().then((res) => {
        if (!res) return;
        this.checkCode()
          .then(() => {
            let params = { ...this.form };
            delete params.code;
            this.loading = true;
            params.passWord = start+window.btoa(this.form.passWord)
            params.qdamnitjm = "1";
            this.$post(this.$api.USER.LOGIN, params)
              .then((response) => {
                if (response.code !== 200) {
                  this.$Message.warning("登陆失败,请确认账号密码或联系管理员");
                  return;
                }
                let resp = response.data;
                localStorage.setItem("userId", resp.id);
                localStorage.setItem("userName", resp.name);
                localStorage.setItem("token", resp.token);
                localStorage.setItem("companyNo", resp.user.companyNo);
                localStorage.setItem("rytoken", resp.rytoken);
                this.$store.dispatch("initApp").then(() => {
                  this.$nextTick(() => {
                    let routeName = this.getNextRoute();
                    this.$router.push({ name: routeName });
                    // this.$router.push({ name: "home" });
                  });
                });
              })
              .finally(() => {
                this.loading = false;
              });
          })
          .catch(() => {
            this.$Message.error("验证码不正确");
            this.getCode();
          });
      });
    },
    getNextRoute(menus) {
      if (this.menuList && this.menuList.levels === 2) return this.menuList.url;
      if (!menus) menus = this.menuList?.children ?? [];
      for (let i = 0; i < menus.length; i++) {
        let menu = menus[i];
        if (menu.url) return menu.url;
        if (menu.children) {
          let subMenu = this.getNextRoute(menu.children);
          if (subMenu) return subMenu;
        }
      }
    },
    keyupEnter(e) {
      if (e.keyCode == 13) {
        this.submit();
      }
    },
    //注册
    register() {
      this.form = {
        userName: "",
        passWord: "",
        accountType: "1",
        code: "",
      };
      this.$refs.form.resetFields();
      this.registerShow = true
      this.isShow = false
    },
    //返回登录
    returnLogin() {
      this.isShow = true;
      this.registerShow = false
      this.modelShow = false
      this.changeCode();
    },
    //找回密码
    getBackPwd(){
      this.modelShow = true
      this.isShow = false
    }
  },
  mounted() {
    this.getCode();
    window.addEventListener("keyup", this.keyupEnter);
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.keyupEnter);
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
</style>